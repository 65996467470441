import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  private readonly translateService = inject(TranslateService);

  languages: string[] = ['es', 'pt', 'en'];
  defaultLanguage: string = 'es';
  translation =
    localStorage.getItem('language') ||
    this.translateService.getBrowserLang() ||
    this.defaultLanguage;

  #document = inject(DOCUMENT);
  isDarkMode = false;

  constructor() {
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localePt, 'pt');

    if (this.isSystemDark()) this.toggleLightDark();
  }

  ngOnInit(): void {
    this.translateService.addLangs(this.languages);
    this.translateService.use(this.translation);
  }

  toggleLightDark() {
    const linkElement = this.#document.getElementById(
      'app-theme',
    ) as HTMLLinkElement;
    if (linkElement.href.includes('light')) {
      linkElement.href = 'theme-dark.css';
      this.isDarkMode = true;
    } else {
      linkElement.href = 'theme-light.css';
      this.isDarkMode = false;
    }
  }

  isSystemDark(): boolean {
    return window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches;
  }
}
