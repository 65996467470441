import { Component, inject, signal } from '@angular/core';
import { Toast } from '../../models/toast-interface';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'toast-container-component',
  standalone: true,
  imports: [],
  templateUrl: './toast-container.component.html',
})
export class ToastContainerComponent {
  toasts = signal<Toast[]>([]);

  private readonly toastService = inject(ToastService);

  ngOnInit() {
    this.toastService.getToasts().subscribe((toasts) => {
      this.toasts.set(toasts);
    });
  }

  removeToast(toast: Toast) {
    this.toastService.removeToast(toast);
  }
}
