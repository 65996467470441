import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ValidateTokenService } from '@src/app/auth/services/validate-token.service';
import { catchError, map, of } from 'rxjs';

export const isLoggedGuard: CanActivateFn = () => {
  const validateTokenService = inject(ValidateTokenService);
  const router = inject(Router);

  return validateTokenService.validateToken().pipe(
    map(() => {
      if (validateTokenService.isLogged()) {
        if (!validateTokenService.user?.activeByAdmin!) {
          if (
            validateTokenService.user?.role !== 'admin' &&
            validateTokenService.user?.role !== 'user'
          ) {
            router.navigate(['/waiting']);
          }
        }
      }

      return validateTokenService.isLogged();
    }),
    catchError(() => {
      localStorage.removeItem('token');

      router.navigate(['/auth/login']);

      return of(false);
    }),
  );
};
