export const environment = {
  production: false,
  baseUrl: 'https://tyboc-pre-production.up.railway.app/api',
  mail: 'contact@tyboc.online',
  tybocUrl: 'www.tyboc.online',
  landingUrl: 'https://tyboc.com',
  nif: 'B56315658',
  companyComercialName: 'Tyboc',
  companyName: 'Gestiones Tyboc S.L.',
  address: 'Calle Azorín 1, escalera II, planta 3, puerta H',
  city: 'Villena (03400), Alicante',
  country: 'España',
  ceo: 'Jose García Navarro',
  number: '673029806',
  mapsUrl: 'https://www.google.com/maps/dir/?api=1',
  stripePublicKey:
    'pk_test_51QA78VRweHn7ho8YKjs0kFnH7Y0Fi9mkzBlRc8O3WkzmWSyc2mjNv3705I0VLljHdGoiPykqE6cZKBfe0w3vvW4f00cH6PviDU',
};
