<div class="fixed bottom-5 right-5 z-50 space-y-4">
  @for (toast of toasts(); track $index) {
    <section
      class="w-[300px] rounded-lg border-l-4 bg-surface-0 p-4 shadow-md dark:bg-surface-800"
      [class]="{
        'border-[#4ade80]': toast.type === 'success',
        'border-[#f87171]': toast.type === 'error',
        'border-[#60a5fa]': toast.type === 'info',
        'border-[#facc15]': toast.type === 'warning',
      }"
    >
      <header class="relative flex items-center gap-2">
        <div class="h-8 w-8">
          @if (toast.type === "success") {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="stroke-[#4ade80]"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
          }
          @if (toast.type === "error") {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="stroke-[#f87171]"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
              <path d="M10 10l4 4m0 -4l-4 4" />
            </svg>
          }
          @if (toast.type === "info") {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="stroke-[#60a5fa]"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
              <path d="M12 9h.01" />
              <path d="M11 12h1v4h1" />
            </svg>
          }
          @if (toast.type === "warning") {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="stroke-[#facc15]"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
              <path d="M12 9v4" />
              <path d="M12 16v.01" />
            </svg>
          }
        </div>

        <p class="font-semibold text-text-light dark:text-text-dark">
          {{ toast.title }}
        </p>

        <button
          class="absolute right-0 top-0 h-5 w-5 cursor-pointer rounded-md hover:bg-surface-100 dark:text-text-dark dark:hover:bg-surface-700"
          (click)="removeToast(toast)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="stroke-black dark:stroke-white"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
          </svg>
        </button>
      </header>

      <div>
        <p class="text-pretty text-sm text-text-light dark:text-text-dark">
          {{ toast.body }}
        </p>
      </div>

      @if (toast.url) {
        <div class="mt-4 flex justify-end">
          <a
            [href]="toast.url"
            target="_blank"
            class="rounded-lg px-2 py-1 text-sm text-text-light"
            [class]="{
              'bg-[#4ade80] hover:bg-[#22c55e]': toast.type === 'success',
              'bg-[#60a5fa] hover:bg-[#3b82f6]': toast.type === 'info',
              'bg-[#facc15] hover:bg-[#eab308]': toast.type === 'warning',
              'bg-[#f87171] hover:bg-[#ef4444]': toast.type === 'error',
            }"
          >
            Ver más
          </a>
        </div>
      }
    </section>
  }
</div>
