import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const notLoggedGuard: CanActivateFn = () => {
  const router = inject(Router);

  if (localStorage.getItem('token')) {
    if (localStorage.getItem('aat')) {
      router.navigate(['/dashboard']);
    } else router.navigate(['/app']);

    return false;
  }

  return true;
};
