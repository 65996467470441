import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { ToastContainerComponent } from './shared/components/toast-container/toast-container.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, ToastContainerComponent],
})
export class AppComponent {
  private readonly translateService = inject(TranslateService);

  languages: string[] = ['es', 'pt', 'en'];
  defaultLanguage: string = 'es';
  translation =
    localStorage.getItem('language') ||
    this.translateService.getBrowserLang() ||
    this.defaultLanguage;

  #document = inject(DOCUMENT);
  isDarkMode = false;

  constructor() {
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localePt, 'pt');
  }

  ngOnInit(): void {
    this.translateService.addLangs(this.languages);
    this.translateService.use(this.translation);
  }

  isSystemDark(): boolean {
    return window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches;
  }
}
