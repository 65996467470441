import { Injectable } from '@angular/core';
import { filter, Subject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { Toast } from '../models/toast-interface';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toasts: Toast[] = [];
  private toastSubject = new Subject<Toast[]>();

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.toasts.forEach((toast) => {
          if (toast.duration) {
            const elapsedTime = Date.now() - toast.timestamp;
            const remainingTime = toast.duration - elapsedTime;

            if (remainingTime > 0) {
              clearInterval(toast.timerId);
              this.startProgressAnimation(toast, remainingTime);
            } else {
              this.removeToast(toast);
            }
          }
        });
        this.toastSubject.next([...this.toasts]);
      });
  }

  getToasts() {
    return this.toastSubject.asObservable();
  }

  success(title: string, body: string, url?: string, duration: number = 5000) {
    this.addToast({
      title,
      body,
      url,
      duration,
      type: 'success',
      timestamp: Date.now(),
      progress: 100,
    });
  }

  error(title: string, body: string, url?: string, duration: number = 5000) {
    this.addToast({
      title,
      body,
      url,
      duration,
      type: 'error',
      timestamp: Date.now(),
      progress: 100,
    });
  }

  info(title: string, body: string, url?: string, duration: number = 5000) {
    this.addToast({
      title,
      body,
      url,
      duration,
      type: 'info',
      timestamp: Date.now(),
      progress: 100,
    });
  }

  warning(title: string, body: string, url?: string, duration: number = 5000) {
    this.addToast({
      title,
      body,
      url,
      duration,
      type: 'warning',
      timestamp: Date.now(),
      progress: 100,
    });
  }

  removeToast(toast: Toast) {
    if (toast.timerId) {
      clearInterval(toast.timerId);
    }

    this.toasts = this.toasts.filter((t) => t !== toast);
    this.toastSubject.next([...this.toasts]);
  }

  private addToast(toast: Toast) {
    toast.progress = 100;
    if (toast.duration) {
      this.startProgressAnimation(toast, toast.duration);
    }

    this.toasts.push(toast);
    this.toastSubject.next([...this.toasts]);
  }

  private startProgressAnimation(toast: Toast, duration: number) {
    const interval = 50;
    const steps = duration / interval;
    const decrement = 100 / steps;

    toast.timerId = setInterval(() => {
      toast.progress -= decrement;
      if (toast.progress <= 0) {
        clearInterval(toast.timerId);
        this.removeToast(toast);
      }
      this.toastSubject.next([...this.toasts]);
    }, interval);
  }
}
