import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ValidateTokenService } from '@src/app/auth/services/validate-token.service';
import { map } from 'rxjs';

export const isAdminActive: CanActivateFn = () => {
  const validateTokenService = inject(ValidateTokenService);
  const router = inject(Router);

  return validateTokenService.validateToken().pipe(
    map(() => {
      if (validateTokenService.isLogged()) {
        if (validateTokenService.user?.activeByAdmin!) {
          router.navigate(['/']);
        }
      } else {
        router.navigate(['/']);
      }

      return validateTokenService.isLogged();
    }),
  );
};
